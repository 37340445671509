<script setup>
import { onMounted, ref, watch, getCurrentInstance } from "vue";
import Navbar from "@/components/layouts/Navbar.vue";
import Footer from "@/components/layouts/Footer.vue";
import Loading from "@/components/Loading.vue";
import * as Animation from "@/libs/gsap";

const LoadingInt = ref(false);

onMounted(() => {
  setTimeout(() => {
    LoadingInt.value = true;
  }, 2000);
  setTimeout(() => {
    Animation.handleActiveLink();
  }, 3000);
});
</script>

<template>
  <Loading v-show="!LoadingInt" />
  <div v-show="LoadingInt">
    <Navbar />
    <RouterView />
    <Footer />
  </div>
</template>