<script setup >
import { onMounted, ref } from "vue";
onMounted(() => {});
</script>
<template>
  <div class="loading" id="loading">
    <div class="loading-icon">
      <app-logo />
    </div>
  </div>
</template>
