<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/libs/i18n";

const { t } = useI18n(); //
const router = useRouter();
const route = useRoute();
const fixedTop = ref(false);
const menuIsOpen = ref(false);
const scrollFunction = () => {
  const mainNavbarSub = document.getElementById("navbar");
  if (mainNavbarSub) {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      fixedTop.value = true;
    } else {
      fixedTop.value = false;
    }
  }
};

const changeLanguage = (code) => {
  menuIsOpen.value = false;
  router
    .push({
      name: router.currentRoute.name,
      params: {
        lang: code,
      },
    })
    .then(() => {
      window.location.reload();
    });
};

onMounted(() => {
  scrollFunction();
  window.addEventListener("scroll", scrollFunction);
});
</script>
<template>
  <!-- <div id="not-fill-navbar"></div> -->
  <div
    id="navbar"
    :class="[
      fixedTop ? 'navbar fixed-top' : 'navbar',
      menuIsOpen ? 'menu-is-open' : '',
    ]"
  >
    <div class="navbar-sub">
      <router-link
        :to="{ name: 'home', hash: '#header', params: { lang: $i18n.locale } }"
        class="brand"
      >
        <app-logo
      /></router-link>
      <button class="menu" @click="menuIsOpen = !menuIsOpen">
        <menu-2-rec-icon />
      </button>
      <div
        class="navbar-content-back"
        @click="menuIsOpen = !menuIsOpen"
        :class="menuIsOpen ? 'show' : ''"
      ></div>
      <div class="navbar-content" :class="menuIsOpen ? 'open' : ''">
        <div class="menu-header">
          <router-link
            :to="{
              name: 'home',
              hash: '#header',
              params: { lang: $i18n.locale },
            }"
            class="brand"
          >
            <app-logo-3
          /></router-link>
          <button class="close-menu" @click="menuIsOpen = !menuIsOpen">
            <cross-rec-icon />
          </button>
        </div>

        <div class="navbar-content-list">
          <router-link
            data-section="header"
            :to="{
              name: 'home',
              hash: '#header',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link active"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Home") }}
          </router-link>
          <router-link
            data-section="about"
            :to="{
              name: 'home',
              hash: '#about',
              params: {
                lang: $i18n.locale,
              },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.About the app") }}
          </router-link>
          <router-link
            data-section="restaurants"
            :to="{
              name: 'home',
              hash: '#restaurants',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Restaurants") }}
          </router-link>
          <router-link
            data-section="features"
            :to="{
              name: 'home',
              hash: '#features',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Application Features") }}
          </router-link>
          <router-link
            data-section="FAQ"
            :to="{
              name: 'home',
              hash: '#FAQ',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.FAQ") }}
          </router-link>
          <a
            class="navbar-content-list-link"
            @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')"
            style="cursor: pointer"
          >
            {{ $i18n.locale == "ar" ? $t("common.en") : $t("common.ar") }}
          </a>
        </div>

        <router-link
          :to="{
            name: 'home',
            hash: '#download-app',
            params: { lang: $i18n.locale },
          }"
          class="home-content-header-content-btn"
          @click="menuIsOpen = false"
        >
          <download-app-icon />
          {{ $t("common.Download the app") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
