
import appLogoIcon from "./app-logo.svg";
import appLogo2Icon from "./app-logo-2.svg";
import appLogo3Icon from "./app-logo-3.svg";
import downloadAppIcon from "./downloadApp.svg";
import trustHeartIcon from "./trustHeart.svg";
import CompletedOrdersIcon from "./CompletedOrders.svg";
import NumberOfProductsFoundIcon from "./NumberOfProductsFound.svg";
import RestaurantsFoundIcon from "./RestaurantsFound.svg";
import usersImagesIcon from "./usersImages.svg";
import starsIcon from "./stars.svg";
import starIcon from "./star.svg";
import appleIcon from "./apple.svg";
import googlePlayIcon from "./google-play.svg";
import minusIcon from "./minus.svg";
import plusIcon from "./plus.svg";
import menu2RecIcon from "./menu-2-rec.svg";
import crossRecIcon from "./cross-rec.svg";
import facebookIcon from "./facebook.svg";
import twitterIcon from "./twitter.svg";
import instagramIcon from "./instagram.svg";
import whatsappIcon from "./whatsapp.svg";

// .......................................................


const svgvendors = (app) => {
    return app
        .component("app-logo", appLogoIcon)
        .component("app-logo-2", appLogo2Icon)
        .component("app-logo-3", appLogo3Icon)
        .component("download-app-icon", downloadAppIcon)
        .component("trust-heart-icon", trustHeartIcon)
        .component("completed-orders-icon", CompletedOrdersIcon)
        .component("number-of-products-found-icon", NumberOfProductsFoundIcon)
        .component("restaurants-found-icon", RestaurantsFoundIcon)
        .component("users-images-icon", usersImagesIcon)
        .component("stars-icon", starsIcon)
        .component("star-icon", starIcon)
        .component("apple-icon", appleIcon)
        .component("google-play-icon", googlePlayIcon)
        .component("minus-icon", minusIcon)
        .component("plus-icon", plusIcon)
        .component("menu-2-rec-icon", menu2RecIcon)
        .component("cross-rec-icon", crossRecIcon)
        .component("facebook-icon", facebookIcon)
        .component("twitter-icon", twitterIcon)
        .component("instagram-icon", instagramIcon)
        .component("whatsapp-icon", whatsappIcon)
    ;
};
export default svgvendors;