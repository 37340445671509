import {
    createApp,
    markRaw
} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n from "@/libs/i18n";
import svgvendors from "@/assets/svgs";
import '@/assets/scss/index.scss';


const app = createApp(App)
svgvendors(app);
app.use(router).use(i18n).mount('#app')